import { NextSeo } from "next-seo";
import { useEffect, useRef } from "react";
import Slider, { Settings } from "react-slick";
import Link from "next/link";
import Image from "next/image";
import { GetServerSideProps, GetServerSidePropsContext } from "next";

import { BestSellers, ProductListItem } from "@/components";
import { ssrAxios } from "@/api/ssr";
import { useApi } from "@/hooks";
import { LandingPageProps } from "@/types";
import { extractDomain } from "@/utils";

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const axios = await ssrAxios(context);

  const adID = context.query.ad;

  if (adID) {
    axios.defaults.headers["x-advertisement-id"] = adID;
  }

  if (
    context.req.headers.referer &&
    !context.req.headers.referer.includes("localhost") &&
    !context.req.headers.referer.includes("vodotech")
  ) {
    axios.defaults.headers["x-referer"] = extractDomain(
      context.req.headers.referer
    );
  }

  try {
    const landingInfo = await axios.get<LandingPageProps>(
      "/products/landing-info/"
    );
    const companyInfo = await axios.get<any>("/company/info/");

    return {
      props: {
        fallback: {
          "/products/landing-info/": landingInfo.data,
          "/company/info/": companyInfo.data,
        },
      },
    };
  } catch (error) {
    return {
      error,
      props: {
        fallback: {
          "/products/landing-info/": {},
          "/company/info/": {},
        },
      },
    };
  }
};

export default function Home() {
  const { data } = useApi<LandingPageProps>("/products/landing-info/");
  const videoRef = useRef<HTMLVideoElement>(null);

  const sliderSettingsPreview: Settings = {
    dots: true,
    slidesToShow: 1,
    infinite: true,
    arrows: false,
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, [videoRef.current]);

  return (
    <>
      <NextSeo
        title={`VODOTECH`}
        description={`Купуйте у VODOTECH, якісна продукція та доступна ціна!`}
        themeColor="#fff"
      />
      <main className="relative">
        <div className="block-slideshow block-slideshow--layout--full block">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="block-slideshow__body">
                  <Slider {...sliderSettingsPreview}>
                    <Link
                      className=""
                      href={data?.landing_media_1110x480.redirect_url || ""}
                    >
                      <video
                        ref={videoRef}
                        playsInline
                        className="video_hero"
                        autoPlay
                        preload="none"
                        loop
                        muted
                      >
                        <source
                          src={`https://vodotech.com.ua${
                            data?.landing_media_1110x480.url || ""
                          }`}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                      <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                        style={{
                          backgroundImage: `url('images/slides/slide-1-mobile.jpg')`,
                        }}
                      ></div>
                    </Link>
                    {/* <Link className="block-slideshow__slide" href="">
                      <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                        style={{
                          backgroundImage: `url('images/slides/slide-1-full.jpg')`,
                        }}
                      ></div>
                      <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                        style={{
                          backgroundImage: `url('images/slides/slide-1-mobile.jpg')`,
                        }}
                      ></div>
                      <div className="block-slideshow__slide-content">
                        <div className="block-slideshow__slide-title">
                          Screwdrivers
                          <br />
                          Professional Tools
                        </div>
                        <div className="block-slideshow__slide-text">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                          <br />
                          Etiam pharetra laoreet dui quis molestie.
                        </div>
                        <div className="block-slideshow__slide-button">
                          <span className="btn btn-primary btn-lg">
                            Shop Now
                          </span>
                        </div>
                      </div>
                    </Link>
                    <Link className="block-slideshow__slide" href="">
                      <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                        style={{
                          backgroundImage: `url('images/slides/slide-1-full.jpg')`,
                        }}
                      ></div>
                      <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                        style={{
                          backgroundImage: `url('images/slides/slide-1-mobile.jpg')`,
                        }}
                      ></div>
                      <div className="block-slideshow__slide-content">
                        <div className="block-slideshow__slide-title">
                          One more
                          <br />
                          Unique header
                        </div>
                        <div className="block-slideshow__slide-text">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                          <br />
                          Etiam pharetra laoreet dui quis molestie.
                        </div>
                        <div className="block-slideshow__slide-button">
                          <span className="btn btn-primary btn-lg">
                            Shop Now
                          </span>
                        </div>
                      </div>
                    </Link> */}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="block block-features block-features--layout--boxed">
          <div className="container">
            <div className="block-features__list">
              <div className="block-features__item">
                <div className="block-features__icon">
                  <Image src="/icons/delivery_icon.png" alt="Delivery Icon" width={48} height={48} />
                </div>
                <div className="block-features__content">
                  <div className="block-features__title">Нова Пошта</div>
                  <div className="block-features__subtitle">
                    Доставка по Україні
                  </div>
                </div>
              </div>
              <div className="block-features__divider" />
              <div className="block-features__item">
                <div className="block-features__icon">
                  <Image src="/icons/support_icon.png" alt="Customer Support Icon" width={48} height={48} />
                </div>
                <div className="block-features__content">
                  <div className="block-features__title">Підтримка клієнтів</div>
                  <div className="block-features__subtitle">Маєте сумніви що обрати? Телефонуйте.</div>
                </div>
              </div>
              <div className="block-features__divider" />
              <div className="block-features__item">
                <div className="block-features__icon">
                  <Image src="/icons/payments_icon.png" alt="Payments Icon" width={48} height={48} />
                </div>
                <div className="block-features__content">
                  <div className="block-features__title">Сплачуйте як зручно</div>
                  <div className="block-features__subtitle">Тільки безпечні платежі</div>
                </div>
              </div>
              <div className="block-features__divider"></div>
              <div className="block-features__item">
                <div className="block-features__icon">
                  <Image src="/icons/good_price_icon.png" alt="Good Prices Icon" width={48} height={48} />
                </div>
                <div className="block-features__content">
                  <div className="block-features__title">Маємо цікаві пропозиції</div>
                  <div className="block-features__subtitle">Завжди оновлений товар</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="block block-banner">
          <div className="container">
            <Link
              href={data?.landing_media_1110x170.redirect_url || ""}
              className="block-banner__body"
            >
              <video
                playsInline
                style={{ width: "100%" }}
                autoPlay
                preload="none"
                loop
                muted
              >
                <source
                  src={`https://vodotech.com.ua${
                    data?.landing_media_1110x170.url || ""
                  }`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </Link>
          </div>
        </div>

        {data?.bestsellers && !!data.bestsellers.length && (
          <BestSellers
            products={data.bestsellers}
            title="Топ продажів"
            ComponentToRender={ProductListItem}
          />
        )}

        {data?.special_offers && !!data.special_offers.length && (
          <BestSellers
            products={data.special_offers}
            title="Спеціальні пропозиції"
            ComponentToRender={ProductListItem}
          />
        )}
      </main>
    </>
  );
}
